<template>
  <div>
    <div class="content" v-if="list.length>0">
      <el-card class="mb20 h103 card" v-for="(item,index) in list" :key="index">
        <div class="news_item pointer" @click="goDetail(item)">
          <div class="box">
            <div class="top_title">
              <div class="title">{{ item.title }}</div>
              <div class="time">{{ item.createtime }}</div>
            </div>
            <div class="txt">
             
              <!-- <div v-html="item.content"></div> -->
              <!-- <img src="../../../../lqtms/lqtms/newsImgFile/img28.jpg" style="width:60px;height:60px;"/> -->
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <template v-else>
      <el-empty :image-size="100" description="暂无数据"></el-empty>
    </template>
  </div>
</template>
<script>
import {getNewsDetailList} from '@/api/news'
import {parseTime} from '@/utils'
let ind = 0
export default {
  props: {
    id: {
      type: [Number, String],
      default: () => ''
    }
  },
  data() {
    return {
      list: []
      
    }
  },
  mounted() {
    
    
    this.getDetail()
  },
  methods: {
    beforeEnter (el) {
      //el.style.opacity = 0
      el.style.paddingTop = '100px'
    },
    // 排队按顺序进场动画
    enter (el, done) {
      console.log('enter => ',el.dataset.index)
      let delay = ind * 100
      ind +=1
      setTimeout(()=>{
        //el.style.opacity = 1
        // el.style.transition = 'opacity 1s '
        el.style.animation = 'bounceInUp 1s'
        // el.style.animation = 'one-in 4s infinite'
        //el.style['animation-iteration-count'] = 1
        el.style.paddingTop = '0px'
        done()
      }, delay)
    },
    getDetail() {
      let params = {
        method: "Query",
        proc: "QSP_GET_NEWSBYCID",
        pars: [
          {newscolumnid: this.$route.params.id}
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      getNewsDetailList(JSON.stringify(params)).then(res => {
        res.msg && res.msg.forEach(item => {
          item.createtime = parseTime(new Date(item.createtime).getTime(), `{y}-{m}-{d} {h}:{i}:{s}`)
        })
        this.list = res.msg
      })
    },
    goDetail(item) {
      this.$router.push({
        name: 'news-detail',
        query: {
          id: item.id,
          // detail: item,
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.h103{
  height: 103px;
}
.mb20 {
  margin-bottom: 20px;
}
::v-deep .content{
  padding:0 20px;
  .card{
    height: 65px;
    &:hover{
      animation: pulse 1s;
      animation-iteration-count: 1;
    }
  }
}
.news_item {
  //   padding: 20px 30px;
  font-size: 12px;
  color: #999999;



  .top_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      font-size: 18px;
      color: #333333;

      &:hover {
        color: #eb5e00;
      }
    }
  }

  .txt {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    div {
      overflow: hidden;
    }
  }
}
</style>
