<template>
  <div class="content">
   
      <div class="banner">
        <banner></banner>
      
    <div class = "test">
      <div class = "test_title"> 
        千度供应链有限公司
        Qiandu supply chain co., LTD
      </div>

     <div class = "test_content">
     &nbsp; &nbsp;&nbsp; &nbsp; 千度供应链有限公司（简称：千度供应链）是一家专业网站运营商，物联网、区块链、云计算、大数据、计算机软硬件开发及销售的高新技术企业；千度科技致力于用信息科技推动物流企业的发展和进步，提供具有自主知识产权的物流企业管理软件、物流行业解决方案；注册资金5000万元人民币，下设财务部、技术部、渠道部、客服部。
       一、中国最大最专业的物流行业门户网站
      千度供应链打造的《中国物流网》云平台是由中国物流网（www.中国物流网.com  www.cu56.com）、呼叫中心、车帮主APP、车帮主TMS、车帮主共享甩挂电商平台、车帮主北斗/GPS定位平台、车帮主视频监控平台及“私人订制”的城市配送、物流金融等解决方案组成。
       二、打造“中国物流大通道骨干网”
      中国物流未来十年是真正的黄金十年，随着国家交通强国方针的确立，国家物流枢纽网络布局建设，整合存量物流基础设施资源，发挥物流枢纽的规模经济效应，推动物流组织方式变革，提高物流整体规模运行效率和现代化水平，千度供应链依托《中国物流网》云平台联合政商企及国家枢纽城市近万家物流专线及赋能企业，打造一票到底、互联互通、全程可视、高效透明的“中国物流大通道骨干网”。
     </div>
    </div>
  </div>
  </div>
</template>
<script>

import Banner from '@/components/Banner'
export default {
  metaInfo: {
    title: '中国物流网,物流,货源,车源,专线',
    meta: [
      {
        name: 'keywords',
        content: '中国物流网,物流,货源,车源,专线'
      },
      {
        name: 'description',
        content: '中国物流网,物流,货源,车源,专线'
      }
    ]
  },
  components: {
    Banner
  },
  data() {
    return {
      dataList: [],
      itemId: null,
    }
  },
  mounted() {
    
  },
  computed: {
    newsIndex() {
      return this.$route.params.id
    }
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.content {
  .el-menu-vertical-demo{
    min-height: calc(100vh - 105px);
    .menu_item{
      &:hover{
        animation: jello 1s;
        animation-iteration-count: 1;
      }
    }
  }
  .banner {
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    background: url("../../assets/banner.png");
    background-size: 100% 100%;
  }

.test {
    width: 100%;
    height: 230px;
   font-size: 24px;
    margin-top: 40px;
  
}

.test_title {
 
  margin-left:35%;
  margin-right:35%;
  font-weight: bolder;
}
.test_content {
    margin-top: 40px;
}

}
</style>



