import request from "@/utils/request";

/**
 * 新闻栏目
 * @param data
 * @returns {*}
 */
export const getNewsList = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post',
    })
}

/**
 * 新闻列表
 * @param data
 * @returns {*}
 */
export const getNewsDetailList = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post'
    })
}

/**
 * http://n9.cu56.com:8080/FileUpLoadApp/SqlDataGet.ashx
 * 获取新闻详情
 * @param data
 * @returns {*}
 */
export const getNewsDetail = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post'
    })
}

