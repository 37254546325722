<template>
<!--  轮播图组件s-->
<div>
  <el-carousel trigger="click" :height="height" :interval="interval">
    <el-carousel-item v-for="item in imgs" :key="item">
      <img :src="item" :alt="item" />
    </el-carousel-item>
  </el-carousel>
</div>
</template>

<script>
export default {
  name: "index",
  props: {
    height: {
      type: String,
      default: ()=> '230px'
    },
    imgs: {
      type: Array,
      default: () => [
        require('@/assets/banner1.png'),
        require('@/assets/banner.png')
      ]
    },
    interval: {
      type: Number,
      default: ()=> 5000
    }
  }
}
</script>

<style lang="scss" scoped>
//.el-carousel__item h3 {
//  color: #475669;
//  font-size: 14px;
//  opacity: 0.75;
//  //line-height: 150px;
//  margin: 0;
//}
//
//.el-carousel__item:nth-child(2n) {
//  background-color: #99a9bf;
//}
//
//.el-carousel__item:nth-child(2n+1) {
//  background-color: #d3dce6;
//}
</style>
