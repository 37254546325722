<template>
  <div class="content">
    <div style="padding: 0 70px">
      <div class="banner">
        <banner></banner>
      </div>
    </div>
    <div class="news_box">
      <el-row>
        <el-col :span="5">
          <div class="left">
            <el-menu
                :default-active="newsIndex"
                @select="goItem"
                class="el-menu-vertical-demo"
            >
              <el-menu-item
                  class="menu_item"
                  v-for="(item, index) in dataList"
                  :key="index"
                  :index="`${item.id}`"
              >
                <i class="el-icon-caret-right"></i>
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <el-col :span="19">
          <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.path"/>
          </transition>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {getNewsList} from '@/api/news'
import Banner from '@/components/Banner'
export default {
  metaInfo: {
    title: '中国物流网,物流,货源,车源,专线',
    meta: [
      {
        name: 'keywords',
        content: '中国物流网,物流,货源,车源,专线'
      },
      {
        name: 'description',
        content: '中国物流网,物流,货源,车源,专线'
      }
    ]
  },
  components: {
    Banner
  },
  data() {
    return {
      dataList: [],
      itemId: null,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    newsIndex() {
      return this.$route.params.id
    }
  },
  methods: {
    goItem(id) {
      this.$router.push({
        name: 'news-item',
        params: {
          id: id
        }
      })
    },
    getList() {
      let params = {
        method: "Query",
        proc: "QSP_GET_NEWSCOLUMN",
        pars: [
          {}
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      getNewsList(JSON.stringify(params)).then(res => {
        console.log(res)
        this.dataList = res.msg
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  .el-menu-vertical-demo{
    min-height: calc(100vh - 105px);
    .menu_item{
      &:hover{
        animation: jello 1s;
        animation-iteration-count: 1;
      }
    }
  }
  .banner {
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    background: url("../../assets/banner.png");
    background-size: 100% 100%;
  }

  .news_box {
    padding: 0 70px;
  }
}
</style>



