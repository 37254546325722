<template>
  <div class="content">
    <h2 class="title">{{detail.title}}</h2>
    <p class="date">{{detail.createtime}}</p>
    <div class="detail">
      <div v-html="detail.content"></div>
    </div>
    <p class="footer_txt">责任编辑：{{detail.createman}}</p>
  </div>
</template>
<script>
import {getNewsDetail} from '@/api/news'
import {parseTime} from "@/utils";

export default {
  data() {
    return {
      html: ``,
      detail: {}
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let params = {
        method:"Query",
        proc:"QSP_GET_NEWSBYID",
        pars:[
          {id:this.$route.query.id}
        ],
        tmscode:"89569",
        loginsite:"全部",
        token:""
      }
      getNewsDetail(JSON.stringify(params)).then(res => {
        console.log('detail',res)
        this.detail = res.msg[0]
        this.detail.createtime = parseTime(new Date(res.msg[0].createtime).getTime())
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding: 80px 30px;
  overflow: hidden;
  .title{
    font-size: 32px;
    color: #333333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
  }
  .date{
    color: #999;
    font-size: 13px;
    margin-bottom: 40px;
    padding: 0 10px;
  }
  .detail{
    margin-bottom: 40px;
  }
  .footer_txt{
    text-align: right;
    font-size: 14px;
    color: #999999;
  }
}
</style>
